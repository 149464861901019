import { Box, CircularProgress, Typography } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.main,
    },
  })
)

export default function Loading() {
  const classes = useStyles({})
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(true)
  }, [open])
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={open}
        transitionDuration={2000}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
