import routes from '../Router/RootRoutes'

interface iGlobalState {
  routes: any
  drawerOpen: boolean
  Errors: any
}
export const GlobalInitialState: iGlobalState = {
  routes: routes,
  drawerOpen: true,
  Errors: {
    Error: false,
    Message: '',
  },
}

export function RootReducer(state, action) {
  switch (action.type) {
    case 'routes':
      return {
        ...state,
        routes: action.payload,
      }
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload }
    case 'Errors':
      return { ...state, Errors: action.payload }

    default:
      throw new Error()
  }
}
