import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { YoutubeSearchedFor } from '@material-ui/icons'
import React, { FunctionComponent } from 'react'
import { BaseProps } from 'utils/models'

/**
 * # Suggestion
 *
 * ## Thin Wrapper Policy
 * - We should try to make our components as thin as possible a wrapper to reduce complexity
 * and interoperability issues.
 *
 * ## Sectional Parameters Policy
 * - Sections can either take a React component or parameters for JSX interpolation.
 * - If a React component is provided, it will replace the entire section and the parameters will be ignored.
 */

export interface StandardDialogProps extends DialogProps {
  component?: FunctionComponent
  sections?: {
    header?: {
      component?: FunctionComponent
      title?: string
      subtitle?: FunctionComponent | string
      total?: FunctionComponent | string
      actions?: {
        displayText?: string
        props?: BaseProps<ButtonProps>
      }[]
    }
    body?: FunctionComponent
    footer?: {
      component?: FunctionComponent
      actions?: {
        displayText?: string
        props?: BaseProps<ButtonProps>
      }[]
    }
  }
}

export function StandardDialog(props: StandardDialogProps) {
  /* const classes = useStyles() */

  return (
    <Dialog {...props /* thin wrapper policy, hence just `...props` */}>
      {props?.component?.({}) || (
        <>
          <DialogTitle className="dialog-title">
            {props?.sections?.header?.component?.({}) || (
              <Grid container spacing={1}>
                <Grid container item xs={12} sm={12}>
                  <Grid item xs={8}>
                    <Typography className="dialog-title color-primary-orange">
                      {props?.sections?.header?.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm>
                    {(() => {
                      const total = props?.sections?.header?.total
                      return total ? (
                        <Typography className="dialog-subtitle" align="right">
                          {total}
                        </Typography>
                      ) : (
                        ''
                      )
                    })()}

                    {props?.sections?.header?.component?.({}) ||
                      props?.sections?.header?.actions?.map((f, i) => (
                        <Button
                          variant="contained"
                          size="small"
                          className="btn-dialog btn-reset"
                          key={i}
                          {...f.props}
                        >
                          <YoutubeSearchedFor className="dialog-reset-icon" />
                          {f.displayText}
                        </Button>
                      ))}
                  </Grid>

                  <Grid item xs={12}>
                    {(() => {
                      const subtitle = props?.sections?.header?.subtitle
                      return subtitle ? (
                        <Typography className="dialog-subtitle">
                          {subtitle}
                        </Typography>
                      ) : (
                        ''
                      )
                    })()}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogTitle>
          <DialogContent className="dialog-content">
            {props?.sections?.body?.({})}
          </DialogContent>
          <DialogActions className="dialog-footer">
            {props?.sections?.footer.component?.({}) ||
              props?.sections?.footer.actions.map((v, i) => (
                <Button
                  variant="contained"
                  size="small"
                  className="btn-dialog btn-primary"
                  key={i}
                  {...v.props}
                >
                  {v.displayText}
                </Button>
              ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
