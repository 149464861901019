let accessToken = "";
let registrationToken = ''


export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  return accessToken;
};




export const ClearAccessToken = () => {
  accessToken=''
  localStorage.removeItem("key")
 };

export const setRegistrationToken = (s: string) => {
  registrationToken = s
}

export const getRegistrationToken = () => {
  return registrationToken
}
