export const SystemMsgs = {
  name: () => {
    return 'Name is required'
  },
  userName: () => {
    return 'Login Name is required'
  },
  contactNo: () => {
    return 'Contact No is required'
  },
  requestedBy: () => {
    return 'Requested by is required'
  },
  contactPerson: () => {
    return 'Contact Person is required'
  },
  mobileNo: () => {
    return 'Mobile No is required'
  },
  specialInstruction: () => {
    return 'Special instruction is required'
  },
  createNewRecord: () => {
    return 'Record added successfully.'
  },
  updateRecord: () => {
    return 'Record updated  successfully.'
  },
  type: () => {
    return 'Type is required'
  },
  subject: () => {
    return 'Subject is required'
  },
  travelTo: () => {
    return 'Travel to is required'
  },
  travelFrom: () => {
    return 'Travel from is required'
  },
  purposeOfTravel: () => {
    return 'Purpose of travel is required'
  },
  modeOfTransport: () => {
    return 'Mode of transport is required'
  },
  title: () => {
    return 'Title is required'
  },
  leaveType: () => {
    return 'Leave type is required'
  },
  Remark: () => {
    return 'Remark is required'
  },
}
