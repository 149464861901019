import React, { lazy } from 'react'
const LogOut = lazy(() =>
  import('./LogOut').then(module => ({
    default: module.LogOut,
  }))
)

const logOutRoutes = [
  {
    props: { exact: true, path: '/logout' },
    component: <LogOut />,
  },
]

export default logOutRoutes
