import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import React, { Suspense } from 'react'
interface SuspenseProps {
  children: any
}
const RouteSuspense = (props: SuspenseProps) => {
  const { children } = props
  return <Suspense fallback={<Loading />}>{children}</Suspense>
}

export default RouteSuspense
