import { makeStyles } from '@material-ui/core/styles'

export const useCommonStyles = makeStyles(theme => ({
  root: {},
  // Submenu Icon
  icon: {
    color: '#0019d8',
  },
  // Form Card wrapper
  formCard: {
    margin: '6px',
    padding: '10px',
  },
  title: {
    fontWeight: 500,
  },
  addContact: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: '#0020d8',
    maxWidth: '50%',
    marginRight: '6px',
  },
  label: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  blueLabel: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#2f47ff',
    textDecoration: 'underline',
  },
  contactDetail: {
    marginTop: '6px',
    width: '100%',
    fontSize: '10px',
    display: 'flex',
    fontWeight: 500,
    color: '#212121',
  },
  deleteContact: {
    padding: 0,
    flex: 1,
    textAlign: 'right',
    '& .MuiIconButton-label': {
      display: 'block',
    },
  },
  gridItemLeft: {
    paddingRight: '10px',
  },
  gridItemRight: {
    paddingLeft: '10px',
  },
  gridDetails: {
    '& .MuiGrid-grid-xs-6': {
      marginBottom: '6px',
    },
    '& .MuiGrid-grid-xs-12': {
      marginBottom: '6px',
    },
  },
  chip: {
    '& .MuiChip-root': {
      background: '#ffedcc',
    },
  },
  contentCard: {
    '& .MuiCardContent-root': {},
  },

  categoryTableHeader: {
    backgroundColor: '#cccccc',
    padding: '6px',
    width: '100%',
    display: 'flex',
  },
  productItem: {
    '& .MuiFormControl-root': {
      maxWidth: '60%',
    },

    '& .MuiOutlinedInput-input': {
      padding: '4px',
      textAlign: 'right',
    },
  },
}))
export default useCommonStyles
