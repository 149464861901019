import { gql } from 'apollo-boost'

export const GET_ACCOUNT_DETAIL = gql`
  query getAccountDetail($accountID: String!) {
    getAccountDetail(accountID: $accountID) {
      name
      regNo
      contactNo
      email
      address {
        address
        country
        city
        state
        postCode
        AddressType
      }
      #   subscription{
      # remark
      # software{
      #   package{
      #   name
      #   }
      # }
      # }
    }
  }
`

export const GET_PACKAGES_DETAIL = gql`
  query getPackageByAccount($softwareCode: String!, $accountID: String!) {
    getPackageByAccount(softwareCode: $softwareCode, accountID: $accountID)
  }
`
