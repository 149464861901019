import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  useApproverConfirmedEmailActionMutation,
  useEmployeeLogoutMutation,
} from 'generated/graphql'
import { setAccessToken } from 'AccessToken'

export const ConfirmEmail = () => {
  const [insertedSuccesfully, setInsertedSuccesfully] = useState(null)

  const { token }: any = useParams()

  const [ConfirmedEmailAction] = useApproverConfirmedEmailActionMutation({
    onCompleted: data => {
      if (data.ApproverConfirmedEmailAction === true) {
        setInsertedSuccesfully('true')
      } else {
        setInsertedSuccesfully('false')
      }
    },
  })

  useEffect(() => {
    ConfirmedEmailAction({
      variables: {
        token: token,
      },
    })
  }, [])

  return (
    <>
      {insertedSuccesfully == 'true' ? (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <h1>Email Confirmed</h1>
          <p>Your email address has been successfully confirmed.</p>
          <p>
            Thank you for verifying your email. No further action is required.
          </p>
          <p>
            If you have any questions or need assistance, please contact the
            support team at hrxsupport@ifca.com.my.
          </p>
        </div>
      ) : insertedSuccesfully == 'false' ? (
        <>
          <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>Email Already Verified</h1>
            <p>
              Your email address has already been verified. No further action is
              required.
            </p>
            <p>
              If you have any questions or need assistance, please contact the
              support team at hrxsupport@ifca.com.my.
            </p>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  )
}
