import { setAccessToken } from '@hr-root/hr-employee-react/src/AccessToken'
import { Browser } from '@capacitor/browser'

const hrNodeUrl =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hrx-dev-api.ifca.io/hrx'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hrx-uat-api.ifca.io/hrx'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://hrxapi.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'https://ifcaapi.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'dev-v2-build'
    ? 'https://hrx-dev-api.ifca.io/hrx-v2'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'https://ifcaapi-uat.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'https://hrms-api.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'https://hrms-uat-api.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'https://api.eon.com.my/hrx'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'https://api-uat.eon.com.my/hrx'
    : 'http://localhost:7000/hrx'

const hrNodeRefreshUrl =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hrx-dev-api.ifca.io/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hrx-uat-api.ifca.io/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://hrxapi.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'https://ifcaapi.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'dev-v2-build'
    ? 'https://hrx-dev-api.ifca.io/refresh_token_hrx-v2'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'https://ifcaapi-uat.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'https://hrms-api.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'https://hrms-uat-api.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'https://api.eon.com.my/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'https://api-uat.eon.com.my/refresh_token_hrx'
    : 'http://localhost:7000/refresh_token_hrx'

export const redirectToAnotherApp = async (
  path: string,
  module: string,
  token: string,
  Map,
  mobile = false
) => {
  // Replace this with the actual URL of the target app
  // Map come from Drawer.tsx

  let url = ''
  let prefix = ''

  const currentMap = Map?.find(m => m.module == module)

  switch (process.env.REACT_APP_API_URL) {
    case 'ifca-build':
      url = currentMap?.ifca_url
      break
    case 'prod-v5-build':
      url = currentMap?.prod_url
      break
    case 'prod-clone-build':
      url = currentMap?.prod_clone_url
      break
    case 'uat-v5-build':
      url = currentMap?.uat_url
      break
    case 'dev-v2-build':
      url = currentMap?.dev_url
      break
    case 'prod-sehsa-build':
      url = currentMap?.sehsa_url
      break
    case 'prod-sehuk-build':
      url = currentMap?.sehuk_url
      break
    case 'prod-mbi-build':
      url = currentMap?.mbi_url
      break
    case 'demo-build':
      url = currentMap?.demo_url
      break
    case 'prod-eon-build':
      url = currentMap?.eon_url
      break
    case 'uat-eon-build':
      url = currentMap?.eon_uat_url
      break
    default:
      url = currentMap?.local_url
  }

  switch (process.env.REACT_APP_API_URL) {
    case 'ifca-build':
    case 'prod-v5-build':
    case 'prod-clone-build':
    case 'uat-v5-build':
    case 'dev-v2-build':
    case 'prod-mbi-build':
    case 'demo-build':
    case 'prod-eon-build':
    case 'uat-eon-build':
      prefix = 'https'
      break
    default:
      prefix = 'http'
  }

  if (!url || !prefix || !token) {
    return window.alert('routing error!')
  }

  // Update refresh token before redirecting to other module
  // Purpose: To prevent user from being logged out when accessing other module (occassionally happened even user is not idle for a long time)
  const isMobile =
    mobile ||
    window?.location?.href?.split('?')?.find(x => x.includes('mobile'))

  const fetchWithConfig = async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
    return response.json()
  }

  const handleAuthentication = async isMobile => {
    try {
      let userID

      if (isMobile) {
        userID = JSON.parse(localStorage.getItem('loginInfo'))?.['ID']
      } else {
        const verifyTokenQuery = `
          query VerifyRedirectDomainToken($Token: String!) {
            VerifyRedirectDomainToken(Token: $Token)
          }
        `
        const { VerifyRedirectDomainToken } = await fetchWithConfig(hrNodeUrl, {
          query: verifyTokenQuery,
          variables: { Token: token },
        })
        userID = VerifyRedirectDomainToken
      }

      const { accessToken, user } = await fetchWithConfig(hrNodeRefreshUrl, {
        userID,
      })
      console.log('Access token: ', accessToken)
      setAccessToken(accessToken)
      localStorage.setItem('loginInfo', JSON.stringify(user))
    } catch (err) {
      console.error('Authentication error:', err)
    }
  }

  await handleAuthentication(isMobile)

  if (mobile)
    await Browser.open({
      url: `${prefix}://${url}${path}?mobile&userID=${token}`,
    })
  else window.location.href = `${prefix}://${url}${path}?token=${token}`
  // window.location.href = `http://localhost:3008${path}?userID=${userID}`
}
