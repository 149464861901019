import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Button, TextField } from '@material-ui/core'

import Logo from 'assets/images/HR-X.png'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import useCommonStyles from 'components/CommonStyles'
import {
  useResetPasswordMutation,
  useResetEmployeePasswordMutation,
} from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { ResetPasswordSchema } from 'yupValidation/yup'
import { yupResolver } from '@hookform/resolvers'
import { encryptMessage } from 'containers/helper/Crypto/JsEncrypt'
import './style.scss'

interface IResetPasswordForm {
  password: string
  confirmPassword: string
}

export const ResetPassword = props => {
  let history = useHistory()
  const { token }: any = useParams()
  const [openAlert, setOpenAlert] = useState(false)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState('')
  const [message, setMessage] = useState('')
  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/login')
  }
  const MESSAGE = 'Password has been reset Successfully. Please Login Again'

  const commonClasses = useCommonStyles({})

  const [
    resetPasswordMutation,
    { data, loading: mutationLoading, error: mutationError },
  ] = useResetPasswordMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    onCompleted: data => {
      console.log('data', data)
      reset()
      setOpen(true)

      // history.push('/')
    },
    variables: {
      password: '',
      token: '',
    },
  })

  // const [
  //   resetEmployeePasswordMutation,
  //   { data, loading: mutationLoading, error: mutationError },
  // ] = useResetEmployeePasswordMutation({
  //   onError: error => {
  //     error.graphQLErrors.map(({ message }) => {
  //       console.log('ERROR', message)
  //       setMessage(message)
  //       setOpenAlert(true)
  //     })
  //   },
  //   onCompleted: data => {
  //     console.log('data', data)
  //     reset()
  //     setOpen(true)

  //     // history.push('/')
  //   },
  //   variables: {
  //     password: '',
  //     token: '',
  //   },
  // })

  const [values, setValues] = React.useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: false,
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  //useForm set DefaultValues, validationSchema
  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
    setError,
    getValues,
  } = useForm<IResetPasswordForm>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(ResetPasswordSchema),
  })
  console.log(errors)

  const onSubmit = (data: any) => {
    resetPasswordMutation({
      variables: {
        password: encryptMessage(data?.password),
        token: token,
      },
    })
  }

  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Change Password',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout logo={Logo} image={Background}>
        <span className="page-title">Reset Password</span>

        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            {mutationLoading && Loading}

            <Controller
              as={TextField}
              fullWidth
              type={'password'}
              helperText={errors?.password?.message}
              error={errors?.password ? true : false}
              autoComplete="off"
              name="password"
              label="New Password*"
              control={control}
              inputRef={register({})}
            />
            <Controller
              as={TextField}
              fullWidth
              type={'password'}
              helperText={errors?.confirmPassword?.message}
              error={errors?.confirmPassword ? true : false}
              autoComplete="off"
              name="confirmPassword"
              label="Confirm New Password*"
              control={control}
              inputRef={register({})}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
              // onClick={() => history.push('/')}
            >
              Submit
            </Button>
          </div>
        </form>
      </AuthLayout>
    </>
  )
}
